/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "top-technology-tackles-the-challenge-of-dinner-parties",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#top-technology-tackles-the-challenge-of-dinner-parties",
    "aria-label": "top technology tackles the challenge of dinner parties permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Top Technology Tackles the Challenge of Dinner Parties"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The SoundNav on board Unitron Stride hearing aids classifies sound into up to seven listening environments. Once the listening situation has been identified, the ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " shift their processing into the mode that best fits the situation. If you want more control, use the phone app or the button on the hearing aids to tweak your settings."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Unitron Stride hearing aids with multi-channel digital processing automatically boosts speech and reduces noise. The Speech Focus and Speech Locator features, along with multiband adaptive directionality, help you pick out the voice of the person that you’re talking with."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Directional microphone technology helps with speech understanding. However, this technology can make it more difficult for a hearing aid user to locate voices. Dynamic Spatial Awareness restores important localization cues in order to present you with a fuller auditory scene."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Sudden sharp sounds may get past conventional compression technology that normally keeps sounds from getting too loud. As a result, you may be annoyed by dishes clanging in the kitchen. Unitron Stride includes AntiShock 2, a fast-acting mechanism to dampen peaks so that sounds are smoother and more natural."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An excellent feedback manager helps the hearing aids give you all of the sound that you need without annoying squealing. You’ll be able to hug your family and friends without the fear that the hearing aids will start whistling."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "discreet-hearing-aids-to-match-your-style",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#discreet-hearing-aids-to-match-your-style",
    "aria-label": "discreet hearing aids to match your style permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Discreet Hearing Aids to Match Your Style"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Stride hearing aids come in a variety of color choices. You may choose to hide the hearing aids with a shade that blends in to your hair. Alternatively, you may go bold with a fashionable combination of two colors. Your hearing care professional may be able to offer you a slim-tube fitting for an even more discreet fit.\nThe Stride M and MR models take size 312 batteries. The MR model offers you the option of a rechargeable battery. If you have a more severe hearing loss, the Stride P and P Dura models take size 13 batteries."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If your day includes sweat and dust, you’ll love the IP68 rating of these hearing aids. This rating, determined by a laboratory, certifies that these hearing aids resist dust and water intrusion."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When it comes time to change your hearing aid settings, just press the button on one hearing aid. The DuoLink feature, if enabled by your hearing care professional, will change the other hearing aid’s settings at the same time."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "cool-accessories-help-you-make-the-most-of-your-investment",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cool-accessories-help-you-make-the-most-of-your-investment",
    "aria-label": "cool accessories help you make the most of your investment permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Cool Accessories Help You Make the Most of Your Investment"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "For listening to television, pair the hearing aids with the optional TV streamer. This device sounds the television sound directly into your hearing aids. Many people find that this signal is much clearer than the sound from the TV speakers."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "You also can invest in a remote microphone accessory. A conversation partner can clip on this microphone, which then sends the person’s voice directly into your hearing aids. This strategy improves the signal-to-noise ratio, making it easier for you to understand someone in a noisy place."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "There’s also an app that you may put on your smart phone. The app allows you to control your hearing aids with a simple tap. You also can rate sound quality as you’re listening to the hearing aids in your daily life. This data may then be shared with your hearing care professional for fine tuning."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The hearing aids include a telecoil that helps you hear better on the telephone and in theaters that have loop systems for the hearing impaired. Just ask your hearing care professional how to switch your hearing aids to the “telecoil” setting."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "easy-buying-experience-with-hearcom",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#easy-buying-experience-with-hearcom",
    "aria-label": "easy buying experience with hearcom permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Easy Buying Experience with hear.com"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Two people may have the same hearing loss on an audiogram but face different challenges in daily living. It all depends on an individual’s lifestyle and coping strategies. For that reason, you should receive a personalized recommendation from a hearing healthcare professional. When you call us at hear.com, our experts will answer your initial questions and help you find the best solution."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you decide to schedule an appointment with one of our local Partner Providers, you can expect the professional to perform a comprehensive evaluation of your hearing loss. Based on your needs, the professional will make a recommendation for a hearing aid that will work best for you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our experts at hear.com will monitor your progress every step of the way. If you need financial assistance to afford hearing aids, we’ll give you information on all your available options. You’ll have 45 days to test drive your new hearing aids. If you are not satisfied, simply return the hearing aids for a full refund."), "\n", React.createElement(LandingPageCta, {
    copy: "Try the Unitron Stride now",
    classes: "c-cta--content"
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
